export const namespaced = true;

export const state = {
  results_ads: [],
  leaderboard_ads: [],
  record_ads: [],
};

export const mutations = {
  SET_RESULTS_ADS(state, ads) {
    state.results_ads = ads;
  },
  SET_LEADERBOARD_ADS(state, ads) {
    state.leaderboard_ads = ads;
  },
  SET_RECORD_ADS(state, ads) {
    state.record_ads = ads;
  },
};

// These actions are only valid for the ad provider that BizWest uses
export const actions = {
  get({ commit, rootState }) {
    if (rootState.ads.leaderboard_ads_script) {
      fetch(rootState.ads.leaderboard_ads_script)
        .then((response) => response.json())
        .then((data) => commit('SET_LEADERBOARD_ADS', data.tags))
        .catch((err) => console.debug(err));
    }

    if (rootState.ads.record_ads_script) {
      fetch(rootState.ads.record_ads_script)
        .then((response) => response.json())
        .then((data) => commit('SET_RECORD_ADS', data.tags))
        .catch((err) => console.debug(err));
    }

    if (rootState.ads.results_ads_script) {
      fetch(rootState.ads.results_ads_script)
        .then((response) => response.json())
        .then((data) => commit('SET_RESULTS_ADS', data.tags))
        .catch((err) => console.debug(err));
    }
  },
};
