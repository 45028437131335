<template>
  <div id="app" :class="appClass">
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Global/Footer.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    ...mapState({
      instanceName: (state) => state.name,
      metaDescription: (state) => state.meta_description,
    }),
    appClass() {
      if (
        !this.$route
      || !this.$route.params
      || !Object.keys(this.$route.params)
      ) {
        return 'indexPage';
      }

      if (this.$route.path.split('/').pop() === 'home') {
        return 'directoryHomePage';
      }

      switch (Object.keys(this.$route.params).length) {
        case 1:
          return 'directoryPage';
        case 2:
          return 'recordPage';
        default:
          return 'indexPage';
      }
    },
  },
  components: {
    Footer,
  },
  metaInfo() {
    const title = `${this.instanceName} Directory`;
    const meta = [
      {
        name: 'description',
        content: this.metaDescription,
      },
    ];

    return { title, meta };
  },
};
</script>
