import Vue from 'vue';
import Vuex from 'vuex';

import state from './state.json';

Vue.use(Vuex);

/** Loads in modules dynamically */
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((moduleFiles, [name, module]) => ({ ...moduleFiles, [name]: module }), {});

/** Exports the whole store */
export default new Vuex.Store({
  state: {
    ...state,
    total_records: 0,
  },
  modules,
  mutations: {
    ADD_TOTAL_RECORDS(state, total) {
      state.total_records += total;
    },
  },
  actions: {
    getRecordCount({ commit, state }) {
      state.total_records = 0;

      const insightsName = state.name.toLowerCase().replace(/ /g, '-');
      const date = new Date();
      const endDate = date.toISOString();
      date.setDate(date.getDate() - 1);
      const startDate = date.toISOString();

      state.directories.forEach(({ slug }) => {
        fetch(`https://usage.algolia.com/1/usage/records/${insightsName}_${slug}?startDate=${startDate}&endDate=${endDate}&granularity=daily`, {
          headers: {
            'X-Algolia-Application-Id': state.algolia_app_id,
            'X-Algolia-API-Key': state.algolia_usage_api_key,
          },
        }).then((response) => response.json())
          .then((json) => {
            commit('ADD_TOTAL_RECORDS', json.records.slice(-1).pop().v);
          });
      });
    },
  },
});
