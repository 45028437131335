import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight, faAngleDown, faPhone, faGlobe, faSearch, faUser, faEnvelope,
  faMapMarker,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

library.add(
  faChevronRight, faAngleDown, faPhone, faGlobe,
  faSearch, faFacebookF, faTwitter, faUser, faEnvelope,
  faMapMarker,
);
Vue.component('fa', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);
