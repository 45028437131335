<template>
  <div>
    <a
      :href="`https://www.facebook.com/share.php?u=${url}`"
      class="button is-dark"
      aria-label="Share on Facebook"
    >
      <fa class="fa-fw" :icon="['fab', 'facebook-f']" />
    </a>
    <a
      :href="`https://www.twitter.com/share?url=${url}`"
      class="button is-dark"
      aria-label="Share on Twitter"
    >
      <fa class="fa-fw" :icon="['fab', 'twitter']" />
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: `${window.location.origin}${this.$route.path}`,
    };
  },
};
</script>
